































import { PropType } from 'vue'
import { mapGetters } from 'vuex'
import ToolTip from './ToolTip.vue'
import TextInput from './TextInput.vue'
import { Answer, AnswerOption, AnswerValue } from '@typesCustom/index'

export default TextInput.extend({
  components: {
    ToolTip,
  },
  props: {
    options: {
      type: Array as PropType<AnswerOption[]>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['optionVisuals']),
  },
  data() {
    return {
      selected: [] as string[],
    }
  },
  methods: {
    visual(value: AnswerValue): string {
      const active = this.selected.includes(value as string)
      const visuals = this.optionVisuals(this.id, active)
      return visuals ? visuals[String(value)] : ''
    },
    selectAnswer(value: string) {
      this.selected = this.selected.includes(value)
        ? this.selected.filter((x: string) => x !== value)
        : [...this.selected, value]
      this.$store.dispatch('saveAnswer', { id: this.id, value: this.selected })
      this.$emit('change', { id: this.id, value: this.selected })
    },
  },
  created() {
    const answer: Answer = this.answers.find((x: Answer) => x.id === this.id)
    if (answer && answer.value) {
      this.selected = answer.value as string[]
    }
  },
})
